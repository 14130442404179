.card {
  border: none;
  background: #eee;
}

.search {
  width: 100%;
  margin-bottom: auto;
  background-color: #fff;
  /* padding: 10px; */
}


.search .search-input {
  padding: 0 5px;
  width: 70%;
  caret-color: #536bf6;
  font-size: 19px;
  font-weight: 300;
  color: black;
  transition: width 0.4s linear;
}


.search-icon {
  /* float: right;
      bottom: 1px;
      right: 166px;
      top: 4px;   */
}

.search-icon:hover {

  color: #fff !important;
}

.search-icon-item {
  font-size: 25px;
}





.card-inner {
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  border: none;
  cursor: pointer;
  transition: all 2s;
}


.card-inner:hover {

  transform: scale(1.1);

}

.mg-text span {

  font-size: 12px;

}

.mg-text {

  line-height: 14px;
}

.main {
  background-color: red;
  width: 60%;
  padding-bottom: 1%;
  /* margin: 0 auto !important; */
}

.main-2 {
  background-color: blue;
  width: 30%;
}

.layout-search {
  background-color: rgb(248, 244, 244);
  width: 70%;
  height: 650px;
  margin: 0 auto;
  /* margin-top: 10%; */
  position: relative;
}

.text {
  color: rgb(70, 101, 180);
  font-size: 100px;
  font-style: italic;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding-top: 10%;
}

.main-checkbox {
  width: 100%;
  text-align: center;
}

.main-checkbox-all {
  width: 80%;
  /* margin: 0 auto */
  margin-left: 18%;
}

.main-checkbox-new-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main-checkbox-new {
  text-align: left;
  //width: 50%;
}

/* .btn-cart{
      color: rgb(233, 192, 115);
    } */
.icon-cart {
  color: rgb(243, 173, 173);
  width: 30px;
  height: 30px;
}

/* .main-all-page {
  background-color: red;
  width: 100%;
  height: 961px;
} */

.form-control {
  width: 50% !important;
}

.auto-fill-item {
  padding: 5px;
  margin: 1px;
  border: #536bf6;
}