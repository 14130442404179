body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.rdt_TableHeadRow {
	background-color: #b7cbf1 !important;
}

.tableFixHead {
	overflow: auto;
	height: 250px;
	border: 1px solid #dee2e6;
}
.tableFixHead thead th {
	position: sticky;
	top: 0;
	z-index: 1;
	background: #eee;
	padding: 0 0.5rem !important;
}
.mytr td{
	padding: 0 0.5rem !important;
}
.flex-center{
	display: flex;
	justify-content: center;
	align-items: center;
}
.flex-start{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}